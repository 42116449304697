import Vue from 'vue';
import Vuex from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';

import {
  StoreState,
  DataMap,
  OrgQuery,
  SearchResult,
  SuggestResult,
} from './store.entities';
import { G } from '../common/utils';

const nop = () => {
  // do nothing.
};
let defaultImgsize = parseInt(G.mfx.default_imgsize);
if (!(defaultImgsize >= 0 && defaultImgsize <= 3)) {
  defaultImgsize = 1;
}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /**
     * searchUrl毎にデータ管理する入れ物
     */
    dataMap: {} as DataMap,

    /**
     * searchOnloadの検索制御に使用するフラグ
     **/
    searchOnload: false,
    searchOnloadCompleted: false,
    searchCompleted: false,

    /**
     * 言語設定に使用するフラグ
     **/
    lang: '',

    /**
     * 最後の検索リクエストに関する情報
     * 使われたajax-url
     * dataMapのキーに使われる
     */
    searchUrl: '',

    /**
     * 最後の検索リクエストに関する情報
     * カスタマイズされたパラメータ名によるkey-value。
     * ハッシュフラグメントやクエリストリングで与えられたもの。
     * 検索リクエスト発行時にはparamNamesに基づいて標準パラメータ名に変換する。
     * dataMapの値にも存在する
     */
    orgQuery: {} as OrgQuery,

    /**
     *  最後の検索リクエストに関する情報
     *  標準パラメータ名によるkey-value
     *  検索リクエスト発行時に利用する。
     */
    query: {} as OrgQuery,

    /**
     *  最後の検索リクエストに関する情報
     *  最後の検索リクエストに関する情報
     *  ～/x_search.x で取得したレスポンス
     *  ref. https://c.marsflag.com/mf/mfx/1.0/doc/x_search_spec.html#%E3%83%AC%E3%82%B9%E3%83%9D%E3%83%B3%E3%82%B9%E3%83%87%E3%83%BC%E3%82%BF
     */
    searchResult: {} as SearchResult,

    /**
     *  最後の検索リクエストに関する情報
     *  検索リクエスト発行時に実際に使われたパラメータ
     */
    searchResultParams: {} as OrgQuery,

    /**
     *  最後の検索リクエストに関する情報
     *  検索リクエスト発行時に指定した画像サイズ
     *  0, 1, 2, 3 のいずれか
     */
    imgsize: '',

    /**
     *  最後の検索リクエストに関する情報
     *  検索結果を受け取ったときのタイムスタンプ(ms)
     *  ページログ記録に利用する
     */
    viewTime: 0,

    /**
     *  最後のサジェストリクエストに関する情報
     *  サジェストのレスポンスとリクエスト情報
     */
    suggestResult: {} as SuggestResult,

    /**
     *  最後のキーワードランキングリクエストに関する情報
     *  キーワードランキングの情報
     */
    keywordRanking: {
      // url: // キーワードランキングの対応する検索URL(～/x_search.x)
      // キーワードランキングのレスポンスデータ
      result: [],
      use: false,
      url: '',
    },

    /**
     *  最後の関連キーワードリクエストに関する情報
     *  関連キーワードに関する情報
     */
    relatedKeywords: {
      /**
       * Related kwywords API request parameter
       */
      q: '',
      /**
       * Related kwywords API request parameter
       */
      prefix: '',
      // 関連キーワードのレスポンスデータ
      result: [],
      // 関連キーワードを使うか否か
      enabled: false,
      // Last time query parameters
      lastReq: {},
    },

    /**
     * デフォルトの画像サイズ
     * '0', '1', '2', '3' のいずれか
     */
    defaultImgsize: String(defaultImgsize),

    /**
     * 検索リクエスト発行時にjsonp()から返されるFunction
     * リクエストのキャンセルに利用する。
     * レスポンスを受け取ったときに、リクエストとの対応チェックにも利用する。
     */
    searchCancelFunc: nop,

    /**
     * サジェストリクエスト発行時にjsonp()から返されるFunction
     * リクエストのキャンセルに利用する。
     * レスポンスを受け取ったときに、リクエストとの対応チェックにも利用する。
     */
    suggestCancelFunc: nop,

    /**
     *  ページログ記録を抑制するか否か
     */
    disablePagelog: false,

    /**
     *  検索結果が表示されたか否か
     */
    searchResultsMounted: false,

    /**
     *  リザルトフレームを使うか否か
     */
    availableResultframe: false,

    /**
     *  指定したカスタムエレメントにクラス「mf_helper」を設定をする。
     *  解説ページで装飾するために使う。
     */
    mfHelper: '',

    /**
     *  パラメータ名のカスタマイズ設定
     *  GALFSRAM.mfx.param_names でカスタマイズできる。
     */
    paramNames: {
      // 標準パラメータ名: カスタマイズ名
      page: 'page',
      d: 'd',
      doctype: 'doctype',
      q: 'q',
      ct: 'ct',
      sort: 'sort',
      pagemax: 'pagemax',
      imgsize: 'imgsize',
      ajaxUrl: 'ajaxUrl',
      htmlLang: 'htmlLang',
      mf_helper: 'mf_helper',
      ...G.mfx.param_names,
    },
    isDrilldownItemSelected: false,
  } as StoreState,
  actions,
  mutations,
});
