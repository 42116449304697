


























import { mapState } from 'vuex';
import Base from './Base.vue';
import MfDrilldownItem from './DrilldownItem.vue';
import MfDrilldownReset from './DrilldownReset.vue';
import Vue from 'vue';
import { SearchResult, StoreState } from '../store/store.entities';

export default Vue.extend({
  name: 'mf-drilldown',
  components: { MfDrilldownItem, MfDrilldownReset },
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
    },
    headText: {
      type: String,
    },
    resetText: {
      type: String,
      default: 'reset',
    },
    bodyClassSelected: {
      type: String,
      default: 'mf_finder_drilldown_selected',
    },
  },
  data: function () {
    return {};
  },
  computed: {
    stateData(): StoreState {
      const state = this.$store.state;
      return (this.ajaxUrl ? state.dataMap[this.ajaxUrl] : state) || {};
    },
    searchResult(): SearchResult {
      return (this.stateData as any).searchResult;
    },
    ...mapState({
      mfHelper: 'mfHelper',
    }),
    rootClass(): Record<string, boolean> {
      const map = {
        mf_helper: this.mfHelper === 'Drilldown',
      };
      return map;
    },
    trees(): any {
      return (this.searchResult as any)?.dd?.view_trees;
    },
    noselects(): any {
      return (this.searchResult as any)?.dd?.noselect;
    },
    isDrilldownItemSelected() {
      return this.$store.state.isDrilldownItemSelected;
    },
  },
  watch: {
    noselects() {
      if (this.bodyClassSelected) {
        document.body.classList.toggle(
          this.bodyClassSelected,
          this.noselects && !(this.noselects as string[])[0],
        );
      }
    },
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }
    // lang属性を設定
    if (this.lang) {
      this.$store.commit('setLang', this.lang);
    }
  },
});
