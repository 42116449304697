
































import { mapState } from 'vuex';
import Base from './Base.vue';
import Vue from 'vue';
import { RelatedKeywords, StoreState } from '../store/store.entities';
import { RelatedKeyword } from '@/openapi-clients/finder_service';

export default Vue.extend({
  name: 'mf-related-keywords',
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
    },
    headText: {
      type: String,
    },
  },
  computed: {
    stateData(): StoreState {
      const state = this.$store.state;
      return (this.ajaxUrl ? state.dataMap[this.ajaxUrl] : state) || {};
    },
    relatedKeywords(): RelatedKeywords {
      return this.stateData.relatedKeywords;
    },
    ...mapState({
      mfHelper: 'mfHelper',
    }),
    rootClass(): Record<string, boolean> {
      const map = {
        mf_helper: this.mfHelper === 'RelatedKeywords',
      };
      return map;
    },
    items(): RelatedKeyword[] {
      return (this.relatedKeywords as RelatedKeywords)?.result.filter(
        (item) => item.word != this.relatedKeywords.prefix,
      );
    },
  },
  methods: {
    extendRouteQuery(params: any[]): any {
      const resetParam = {
        page: 1,
      };
      return (this.$root as any).extendRouteQuery({
        query: { ...resetParam, ...params },
        searchUrl: this.ajaxUrl,
      });
    },
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }
    // lang属性を設定
    if (this.lang) {
      this.$store.commit('setLang', this.lang);
    }
    this.$store.dispatch('enableRelatedKeywords', { searchUrl: this.ajaxUrl });
  },
});
