







































































































































































































































































































































































import { mapState } from 'vuex';
import Base from './Base.vue';
import Vue from 'vue';
import {
  StoreState,
  SearchResult,
  SuggestResult,
  OrgQuery,
} from '../store/store.entities';
import { SuggestionInfo } from '../openapi-clients/finder_service';
import { debounce } from 'lodash-es';

export default Vue.extend({
  name: 'SearchBox',
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
    },
    serpUrl: {
      type: String,
    },
    iframe: {
      type: String,
    },
    submitText: {
      type: String,
      default: 'search',
    },
    doctypeAllText: {
      type: String,
      default: 'All',
    },
    doctypeCsv: {
      type: String,
      default: 'html,pdf,doc,xls,ppt',
    },
    forceDoctypeCsv: {
      // 20170711 追加
      type: String,
    },
    doctypeDefault: {
      type: String,
      default: 'all',
    },
    doctypeHidden: {
      type: Boolean,
    },
    categoryCsv: {
      type: String,
    },
    forceCategoryCsv: {
      // 20170711 追加
      type: String,
    },
    categoryDefault: {
      type: String,
      default: '',
    },
    categoryAllText: {
      type: String,
      default: 'ALL categories',
    },
    disableCategoryAll: {
      type: Boolean,
    },
    categoryHidden: {
      type: Boolean,
    },
    sortDefault: {
      type: String,
      default: '0',
      validator: function (v) {
        return ['0', '1'].includes(v);
      },
    },
    sortTextCsv: {
      type: String,
      default: 'match,recent',
    },
    sortHidden: {
      type: Boolean,
    },
    pagemaxCsv: {
      type: String,
      default: '10,20,30',
    },
    pagemaxDefault: {
      type: String,
      default: '10',
    },
    pagemaxHidden: {
      type: Boolean,
    },
    imgsizeDefault: {
      type: String,
      default: '1',
      validator: function (v) {
        return ['0', '1', '2', '3'].includes(v);
      },
    },
    imgsizeTextCsv: {
      type: String,
      default: 'none,small,medium,large',
    },
    imgsizeHidden: {
      type: Boolean,
    },
    optionsHidden: {
      type: Boolean,
    },
    suggestMax: {
      type: Number,
      validator: function (v) {
        return v >= 0;
      },
      default: 10,
    },
    disablePagelog: {
      type: Boolean,
      default: false,
    },
    enablePagelog: {
      type: Boolean,
      default: false,
    },
    useLinks: {
      type: Boolean,
      default: false,
    },
    useToggle: {
      type: Boolean,
      default: false,
    },
    submitCallback: {
      type: String,
    },
    onSubmitFunc: {
      // 20170719 追加
      type: Function,
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
    resultCallback: {
      type: String,
    },
    onResultFunc: {
      // 20170719 追加
      type: Function,
    },
    ignoreSearchResult: {
      // 20170627 追加
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    inputTitle: {
      // 20171214 追加
      type: String,
      default: 'search query',
    },
    disableSuggestCursor: {
      type: Boolean,
      default: false,
    },
    /** このアプリがあるページを最初に訪問したときに空の配列の検索をするかしないか */
    searchOnLoad: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      imgsizeMap: {
        '0': 'none',
        '1': 'small',
        '2': 'middle',
        '3': 'large',
      },
      sortPolicyMap: {
        '0': 'match',
        '1': 'recent',
      },
      styleDisplayNone: {
        display: 'none',
      },
      inputChecker: null,
      paramQ: '',
      suggestQ: '',
      suggestPos: 0,
      suggestHover: 0,
      suggestReq: null,
      showSuggest: false,
      cancelSubmit: false,
      doctypeCurrent: '',
      categoryCurrent: '',
      sortCurrent: '',
      pagemaxCurrent: '',
      imgsizeCurrent: '',
      toggleShow: false,
      focusElement: null,
    };
  },
  computed: {
    stateData(): StoreState {
      const state = this.$store.state;
      return (this.ajaxUrl ? state.dataMap[this.ajaxUrl] : state) || {};
    },
    suggestResult(): SuggestResult {
      return this.stateData.suggestResult;
    },
    searchResult(): SearchResult {
      return this.stateData.searchResult;
    },
    searchResultParams(): OrgQuery {
      return this.stateData.searchResultParams;
    },
    stateImgsize(): string {
      return this.stateData.imgsize;
    },
    ...mapState({
      paramNames: 'paramNames',
      mfHelper: 'mfHelper',
    }),
    rootClass(): Record<string, any> {
      const map = {
        mf_finder_searchBox_focusInput: this.inputChecker,
        mf_finder_searchBox_hasQuery: this.paramQ,
        mf_finder_searchBox_useSelects: !(this.useLinks || this.useToggle),
        mf_finder_searchBox_useLinks: this.useLinks,
        mf_finder_searchBox_useToggle: this.useToggle,
        mf_finder_searchBox_useToggle_show: this.toggleShow,
        mf_helper: this.mfHelper === 'SearchBox',
      };
      return map;
    },
    hasRouter(): boolean {
      return !!this.$router;
    },
    doctypeValues(): string[] {
      if (this.forceDoctypeCsv) {
        return this.forceDoctypeCsv.split(/ *, */);
      }
      return this.searchResult?.doctype?.list || this.doctypeCsv.split(/ *, */);
    },
    categoryValues(): string[] {
      if (this.forceCategoryCsv) {
        return this.forceCategoryCsv.split(/ *, */);
      }
      return (
        this.searchResult?.category?.items || this.categoryCsv?.split(/ *, */)
      );
    },
    pagemaxValues(): string[] {
      return this.pagemaxCsv.split(/ *, */);
    },
    sortTextValues(): string[] {
      return this.sortTextCsv.split(/ *, */);
    },
    imgsizeTextValues(): string[] {
      return this.imgsizeTextCsv.split(/ *, */);
    },
    params(): OrgQuery {
      return this.searchResultParams || ({} as OrgQuery);
    },
    doctype(): string {
      return this.searchResult?.doctype?.current || this.doctypeCurrent;
    },
    category(): string {
      return this.searchResult?.category?.selected?.[0] || this.categoryCurrent;
    },
    sort(): string {
      return this.params?.sort || this.sortCurrent;
    },
    drilldown(): string {
      return this.params?.d;
    },
    pagemax(): string {
      return this.params?.pagemax || this.pagemaxCurrent;
    },
    imgsize(): string {
      return this.stateImgsize || this.imgsizeCurrent;
    },
    suggestData(): SuggestionInfo[] {
      return this.suggestResult?.dat || [];
    },
  },
  watch: {
    params() {
      if (!this.ignoreSearchResult) {
        let q, sort, pagemax;
        if (this.params) {
          q = this.params.q;
          sort = this.params.sort;
          pagemax = this.params.pagemax;
        }
        this.paramQ = q || '';
        if (sort) {
          this.sortCurrent = sort;
        }
        if (pagemax) {
          this.pagemaxCurrent = pagemax;
        }
      }
    },
    searchResult() {
      this.resetSuggest();
      this.showSuggest = false;
      // ここを通ってない
      // this.searchResult?.organic?.hitsが 0 or undefined になってる = false扱いになってる
      if (this.searchResult?.organic?.hits && !this.ignoreSearchResult) {
        this.doctypeCurrent =
          this.searchResult?.doctype?.current || this.doctypeCurrent;
        this.categoryCurrent = this.searchResult?.category?.selected?.[0] || '';
      } else {
        this.doctypeCurrent =
          this.searchResult?.doctype?.current || this.doctypeCurrent;
        this.categoryCurrent = this.category;
      }

      this.resetToggleShow();

      const resultCallback = (window as any)[this.resultCallback];
      if (this.resultCallback && typeof resultCallback === 'function') {
        this.$nextTick(() => {
          resultCallback();
        });
      } else if (this.onResultFunc && typeof this.onResultFunc === 'function') {
        this.$nextTick(() => {
          this.onResultFunc();
        });
      }
      // 検索結果を受け取ったらimgsizeCurrentをリセット
      this.pullImgsize();
    },
    stateImgsize() {
      // imgzieだけは検索結果と連動してない
      this.pullImgsize();
    },
    suggestResult() {
      if (this.suggestResult?.req === this.suggestReq) {
        this.showSuggest = true;
      }
      this.resetSuggest();
    },
  },
  created() {
    this.doctypeCurrent = this.$props.doctypeDefault;
    this.categoryCurrent = this.$props.categoryDefault || '';
    this.sortCurrent = this.$props.sortDefault;
    this.pagemaxCurrent = this.$props.pagemaxDefault;
    this.imgsizeCurrent = this.$props.imgsizeDefault;
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }

    // lang属性を設定
    if (this.lang) {
      this.$store.commit('setLang', this.lang);
    }

    this.$store.state.searchOnload = this.searchOnLoad;

    if (this.enablePagelog) {
      // 検索窓ページ(sbox.js)の場合にページログを有効にする
      // 検索結果ページ(serp.js)の場合は無視される
      this.$store.dispatch('startPagelog', {
        searchUrl: this.ajaxUrl,
      });
    }
    if (this.disablePagelog) {
      // 検索結果ページ(serp.js)の場合にページログを無効化する
      // 検索窓ページ(sbox.js)の場合は無視される
      this.$store.commit('disablePagelog');
    }
    this.resetToggleShow();

    if (this.searchOnLoad) {
      this.$nextTick(() => {
        this.search();
      });
    }
  },
  methods: {
    extendRouteQuery(params: Record<string, any>) {
      return (this.$root as any).extendRouteQuery({
        query: params,
        searchUrl: this.ajaxUrl,
      });
    },
    toggle() {
      this.toggleShow = !this.toggleShow;
    },
    resetToggleShow(): void {
      if (!this.toggleShow) {
        this.toggleShow = !(this.doctype === 'all' && this.sort === '0');
      }
    },
    resetSuggest() {
      this.suggestPos = 0;
      this.suggestHover = 0;
    },
    setImgsize(imgsize: string) {
      this.$store.commit('setImgsize', imgsize);
    },
    onInputFocus(ev: Event) {
      this.showSuggest = false;
      this.startInputChecker(ev.target as HTMLInputElement);
    },
    onInputUnfocus() {
      this.clear_inputChecker();
      setTimeout(() => {
        this.showSuggest = false;
      }, 300);
    },
    startInputChecker(input: HTMLInputElement): void {
      this.clear_inputChecker();
      this.suggestQ = input.value;
      this.inputChecker = window.setInterval(() => {
        if (this.suggestQ === input.value) return;
        this.suggestQ = input.value;
        this.update_suggest(input);
      }, 100);
    },
    clear_inputChecker(): void {
      if (this.inputChecker) {
        clearInterval(this.inputChecker);
        this.inputChecker = NaN;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    update_suggest: debounce(function (this: any, input: HTMLInputElement) {
      if (this.suggestMax > 0) {
        const req = (this.suggestReq = {
          input,
          max: this.suggestMax,
          searchUrl: this.ajaxUrl,
        });
        this.$store.dispatch('updateSuggest', req);
      }
    }, 250),
    onInputDown(ev: Event) {
      // console.log(this.disableSuggestCursor);
      if (this.disableSuggestCursor) return;
      if (!this.showSuggest)
        return this.update_suggest(ev.target as HTMLInputElement);

      this.suggestPos++;
      if (this.suggestPos > this.suggestData.length) this.suggestPos = 0;
    },
    onInputUp(ev: Event) {
      if (this.disableSuggestCursor) return;
      if (!this.showSuggest)
        return this.update_suggest(ev.target as HTMLInputElement);

      this.suggestPos--;
      if (this.suggestPos < 0) this.suggestPos = this.suggestData.length;
    },
    onInputEnter(ev: Event) {
      if (this.suggestPos === 0) return;
      if (this.suggestQ !== (ev.target as HTMLInputElement).value) return;
      const item = this.suggestData[this.suggestPos - 1];
      if (!item) return;
      if (ev) ev.preventDefault();
      // ev.target.value = item.word
      this.suggestQ = this.paramQ = item.word;
      this.showSuggest = false;
      this.resetSuggest();
    },
    onSuggestHover(pos: number, flag: boolean) {
      if (flag) {
        this.suggestHover = pos;
      } else if (this.suggestHover === pos) {
        this.suggestHover = 0;
      }
    },
    onSuggestClick(i: number) {
      const item = this.suggestData[i];
      if (!item) return;
      this.suggestQ = this.paramQ = item.word;
      this.$nextTick(() => {
        this.search();
      });
    },
    onFocusSelect(ev: Event) {
      this.focusElement = ev.target;
    },
    onBlurSelect(ev: Event) {
      if (this.focusElement === ev.target) {
        this.focusElement = null;
      }
    },
    onChangeSelect(ev: Event) {
      // 変更されたのがフォーカス中のselectなら発火
      if (
        this.focusElement === ev.target &&
        this.searchResultParams &&
        Object.keys(this.searchResultParams).length
      ) {
        this.search();
      }
    },
    onChangeImgsize(ev: Event) {
      // 変更されたのがフォーカス中のselectなら発火
      if (this.focusElement === ev.target) {
        this.search({ page: this.params.page });
        // this.setImgsize(ev.target.value)
      }
    },
    pullImgsize() {
      if (this.stateImgsize) {
        this.imgsizeCurrent = this.stateImgsize;
      }
    },
    onSubmitForm() {
      this.search();
    },
    search(params?: Record<string, any>) {
      this.showSuggest = false;
      const form = this.$refs.searchbox_form;

      this.$store.dispatch('searchFromForm', {
        form: form,
        searchUrl: this.ajaxUrl,
        serpUrl: this.serpUrl,
        iframe: this.iframe,
        targetBlank: this.targetBlank,
        forceQuery: params,
      });

      const submitCallback = (window as any)[this.submitCallback];
      if (this.submitCallback && typeof submitCallback === 'function') {
        submitCallback(form, this.serpUrl, this.iframe);
      } else if (typeof this.onSubmitFunc === 'function') {
        this.onSubmitFunc(form, this.serpUrl, this.iframe);
      }
    },
  },
});
