import { Commit, Dispatch } from 'vuex';
import router from '../router';
import { pickBy, pick } from 'lodash-es';
import FormSerialize from 'form-serialize';
import qs from 'query-string';
import { StoreState, OrgQuery, OrganicDoc } from './store.entities';
import {
  extractFinderApiPathParts,
  checkResultframeMatchMedia,
  G,
} from '../common/utils';

// Finder service api imports
import {
  SuggestionApi,
  SearchApi,
  Doctype,
} from '../openapi-clients/finder_service';

/**
 * jsonpでリクエスト発行してよいURLか否かをチェックする。
 * state.dataMap[url] があれば許可。
 * GALFSRAM.mfx.ajaxUrlRegExp にurlがマッチすれば許可。
 * GALFSRAM.mfx.ajaxUrlRegExp が空で state.dataMap が空なら許可。
 */
function checkAjaxUrl({ state }: { state: StoreState }, url: string) {
  if (typeof url !== 'string') return false;

  if (G.mfx.ajaxUrlRegExp instanceof RegExp) {
    // パターンが設定されている場合は
    // 登録済みかパターンにマッチするものだけを許可
    return state.dataMap[url] || url.match(G.mfx.ajaxUrlRegExp);
  } else {
    // パターンが設定されてない場合は
    // 登録済みか、登録済みが空の場合だけ許可
    return state.dataMap[url] || Object.keys(state.dataMap).length === 0;
  }
}

const Window: Record<string, any> = window;
Window.GALFSRAM2 = Window.GALFSRAM2 || {};
const G2 = Window.GALFSRAM2;

class Tokenizer {
  whitespaceRegex: RegExp;

  constructor() {
    this.whitespaceRegex = /\s+/;
  }

  /**
   * Simple tokenizer that splits the text on whitespace and returns sequences of non-whitespace characters as tokens. Note that any punctuation will be included in the tokens.
   */
  tokenizeOnWhitespace({ text }: { text: string }): {
    tokens: string[];
  } {
    const returnValue = {
      tokens: [] as string[],
    };
    returnValue.tokens = text.trim().split(this.whitespaceRegex);
    return returnValue;
  }

  /**
   * If text contains more than one token (whitespace tokenized),
   * returns the last token in lastToken and the input text tokens
   * joined with space, but without last token.
   * If text contains only one token or is empty,
   * returns empty lastToken and input text untouched.
   * Tokens are always returned.
   */
  popLastOfMoreThanOneToken({ text }: { text: string }): {
    tokens: string[];
    text: string;
    lastToken: string;
  } {
    const returnValue = {
      tokens: [] as string[],
      text: '',
      lastToken: '',
    };
    const tokens = this.tokenizeOnWhitespace({ text }).tokens;
    returnValue.tokens = [...tokens];
    const lastToken = tokens.pop();
    if (tokens.length > 0 && lastToken) {
      returnValue.text = tokens.join(' ');
      returnValue.lastToken = lastToken;
    } else {
      returnValue.text = text;
    }
    return returnValue;
  }
}

const tokenizer = new Tokenizer();

const actions = {
  /**
   * サジェストリクエストを発行する
   * @param  {Object} req { input: <inputエレメント>, max: <最大サジェスト件数>, searchUrl: <x_search.x URL(オプション)> }
   */
  async updateSuggest(
    { state, commit }: { state: StoreState; commit: Commit },
    req: { input: HTMLInputElement; max: number; searchUrl: string },
  ): Promise<void> {
    // console.log('updateQuery')
    const { input, max } = req;
    const searchUrl = req.searchUrl || state.searchUrl;

    const query = pick(FormSerialize(input.form, { hash: true }), [
      // パラメタ名の変換
      state.paramNames.q,
      state.paramNames.ct,
      'unitid',
    ]);

    if (max) query.max = String(max);

    for (const [k, v] of Object.entries(state.paramNames)) {
      if (k !== v) {
        if (query[k]) {
          delete query[k];
        }
        if (query[v]) {
          query[k] = query[v];
          delete query[v];
        }
      }
    }

    commit('prepDataMap', { searchUrl });

    // TODO: query.referer どうするか
    // if (document.referrer) query.referer = document.referrer;

    const finderApiPathParts = extractFinderApiPathParts(searchUrl);
    if (!finderApiPathParts) return;

    //dummy
    const cancelFunc = () => {
      // do nothing.
    };

    commit('loadingSuggest', { cancelFunc });

    const suggestionApi = new SuggestionApi(
      undefined,
      finderApiPathParts.baseUrl,
    );
    let res;
    if (finderApiPathParts.serviceUniqueName) {
      res = await suggestionApi.suggestByUniqueName(
        finderApiPathParts.serviceUniqueName,
        (query.q as string) || undefined,
        'utf8',
        max,
      );
    } else {
      res = await suggestionApi.suggest(
        finderApiPathParts.ids.contractId,
        finderApiPathParts.ids.servicegroupId,
        finderApiPathParts.ids.serviceId,
        (query.q as string) || undefined,
        'utf8',
        max,
      );
    }
    commit('receiveSuggest', {
      cancelFunc,
      dat: res.data.suggestions,
      req,
      searchUrl,
    });
  },

  /**
   * FORMから検索を実行する
   * @param  {HTMLFormElement} form    FormSerializeの対象とするformエレメント
   * @param  {string} searchUrl   ～/x_search.x
   * @param  {string} serpUrl     遷移先検索結果ページのURL。(このURLに「?key=val&...」が付加される。)
   * @param  {string} iframe      検索結果をiframeに表示する場合に、iframeを特定するためのquerySelector を指定する。
   * @param  {boolean} targetBlank 検索結果を別ウィンドウで表示するか否か。
   * @param  {object} forceQuery  formから得られる検索パラメタを、この値で上書きする。
   */
  searchFromForm(
    { state }: { state: StoreState },
    {
      form,
      searchUrl,
      serpUrl,
      iframe,
      targetBlank,
      forceQuery,
    }: {
      form: HTMLFormElement;
      searchUrl: string;
      serpUrl: string;
      iframe: string;
      targetBlank: boolean;
      forceQuery: Record<string, unknown>;
    },
  ): void {
    searchUrl = searchUrl || state.searchUrl;

    const querySerialized = FormSerialize(form, { empty: true, hash: true });
    // radioの値が空文字だと捨てられるので対策T_T
    let query = {
      [state.paramNames.ct]: '',
      ...querySerialized,
      ...forceQuery,
    };
    // console.log('query:' + JSON.stringify(query))

    let url;

    const defaultSerpUrl = router.mode === 'hash' ? '#/' : '';

    if ((serpUrl && serpUrl !== defaultSerpUrl) || iframe || targetBlank) {
      query = {
        [state.paramNames.ajaxUrl]: searchUrl,
        [state.paramNames.htmlLang]:
          state.lang || document.getElementsByTagName('html')[0].lang || 'ja',
        ...query,
      };
      url = `${serpUrl || defaultSerpUrl}?${qs.stringify(query)}`;
    } else {
      query = {
        [state.paramNames.ajaxUrl]: searchUrl,
        [state.paramNames.htmlLang]:
          state.lang || document.getElementsByTagName('html')[0].lang || 'ja',
        ...query,
      };

      router.push({ path: '', query: query as any }).catch((e) => e);
      return;
    }

    if (iframe) {
      const elm: HTMLIFrameElement = document.querySelector(iframe);
      if (elm) {
        elm.src = url;
      } else {
        console.error(`can't find element of "${iframe}"`);
      }
    } else if (targetBlank) {
      window.open(url, '_blank');
    } else {
      location.href = url;
    }
  },

  /**
   * クエリパラメータに基づいて実際にjsonpで検索リクエストを実行する
   * @param  {Object} orgQuery クエリパラメータ
   */
  async fetchSearchDataByQuery(
    {
      state,
      commit,
      dispatch,
    }: { state: StoreState; commit: Commit; dispatch: Dispatch },
    orgQuery: OrgQuery,
  ): Promise<void> {
    // パラメタ名の変換
    // console.log({ fetchSearchDataByQuery: { orgQuery } });
    let query: any = { ...orgQuery };
    for (const [k, v] of Object.entries(state.paramNames)) {
      if (k !== v) {
        if (query[k]) {
          delete query[k];
        }
        if (query[v]) {
          query[k] = query[v];
          delete query[v];
        }
      }
    }
    // console.log({ fetchSearchDataByQuery: { query } });

    let searchUrl = state.searchUrl;
    if (query.ajaxUrl) {
      if (checkAjaxUrl({ state }, query.ajaxUrl)) {
        searchUrl = query.ajaxUrl;
        dispatch('updateSearchUrl', query.ajaxUrl);
      } else {
        console.error(`${query.ajaxUrl} is not allowed as ajaxUrl`);
      }
      delete query.ajaxUrl;
    }

    commit('prepDataMap', { searchUrl });

    // console.log('fetchSearchDataByQuery')
    if (Object.keys(query).length === 0) {
      commit('setQuery', { query, orgQuery, searchUrl });
      commit('searchCancel');
      commit('loadingSearch', { searchUrl });
      commit('setSearchResult', { dat: {}, searchUrl });
      return;
    }
    // console.log('query:')
    // console.log(query)
    commit('setMfHelper', query.mf_helper);
    const ex = {
      htmlLang: query.htmlLang,
      imgsize: query.imgsize,
    };
    let picked;
    const oldQuery = state.dataMap[searchUrl].query;
    if (oldQuery) {
      picked = pickBy(query, (v, k) => {
        return !(
          (oldQuery as any)[k] === v ||
          Object.prototype.hasOwnProperty.call(ex, k)
        );
      });
    } else {
      picked = query;
    }
    // console.log('query:' + JSON.stringify(query))
    // console.log('picked:' + JSON.stringify(picked))
    commit('setQuery', { query, orgQuery, searchUrl });

    if (state.lang) {
      commit('setHtmlLang', state.lang);
    } else if (ex.htmlLang) {
      commit('setHtmlLang', ex.htmlLang);
    }
    if (ex.imgsize) commit('setImgsize', { imgsize: ex.imgsize, searchUrl });

    if (Object.keys(picked).length === 0) {
      return;
    }

    commit('searchCancel');

    let imgsize = state.dataMap[searchUrl].imgsize;
    if (!imgsize) imgsize = state.defaultImgsize;
    query = {
      imgsize: imgsize,
      ...state.dataMap[searchUrl].searchResultParams,
      ...query,
    };
    // console.log(JSON.stringify(state.searchResultParams))
    commit('setSearchResultParams', { query, searchUrl });

    // TODO: query.referrer どうするか
    // if (document.referrer) query.referer = document.referrer;

    const finderApiPathParts = extractFinderApiPathParts(searchUrl);
    if (!finderApiPathParts) return;

    //dummy
    const cancelFunc = () => {
      // do nothing.
    };

    const params: {
      sortBy?: string[];
      doctype?: Doctype[];
      drilldown?: string[];
    } = {};
    if (query.sort)
      params.sortBy = [['score', 'last_modified'][query.sort as number]];
    if (query.doctype) params.doctype = [query.doctype] as Doctype[];
    if (query.d) params.drilldown = [(query.d as string) || ''];

    commit('loadingSearch', { cancelFunc, searchUrl });
    // TODO: 排他制御する
    const searchApi = new SearchApi(undefined, finderApiPathParts.baseUrl);
    let res;
    if (finderApiPathParts.serviceUniqueName) {
      res = await searchApi.searchByServiceUniqueName(
        finderApiPathParts.serviceUniqueName,
        query.q || undefined,
        undefined,
        query.page || undefined,
        query.pagemax || undefined,
        params.sortBy,
        query.ct || undefined,
        params.drilldown,
        params.doctype,
      );
    } else {
      res = await searchApi.search(
        finderApiPathParts.ids.contractId,
        finderApiPathParts.ids.servicegroupId,
        finderApiPathParts.ids.serviceId,
        query.q || undefined,
        undefined,
        query.page || undefined,
        query.pagemax || undefined,
        params.sortBy,
        query.ct || undefined,
        params.drilldown,
        params.doctype,
      );
    }
    commit('setSearchResult', {
      cancelFunc,
      dat: res.data,
      query,
      searchUrl,
    });

    if (
      state.dataMap[searchUrl].relatedKeywords.enabled &&
      state.dataMap[searchUrl].relatedKeywords.lastReq.q !== query.q
    ) {
      const req: {
        q: string;
        max: number;
      } = { q: query.q + ' ', max: 20 };

      const finderApiPathParts = extractFinderApiPathParts(searchUrl);
      if (!finderApiPathParts) return;

      const qSplit = tokenizer.popLastOfMoreThanOneToken({ text: query.q });
      commit('saveRelatedKeywordsParams', {
        req,
        searchUrl,
        // change to use query.q instead of qSplit.text in order to get the correct q value (2024/07)
        q: query.q,
        // prefix is used for remove same keyword from related keywords
        prefix: qSplit.lastToken,
      });

      const suggestionApi = new SuggestionApi(
        undefined,
        finderApiPathParts.baseUrl,
      );
      let res;
      if (finderApiPathParts.serviceUniqueName) {
        res = await suggestionApi.getRelatedKeywordsByUniqueName(
          finderApiPathParts.serviceUniqueName,
          qSplit.text || undefined,
          qSplit.lastToken || undefined,
        );
      } else {
        res = await suggestionApi.getRelatedKeywords(
          finderApiPathParts.ids.contractId,
          finderApiPathParts.ids.servicegroupId,
          finderApiPathParts.ids.serviceId,
          qSplit.text || undefined,
          qSplit.lastToken || undefined,
        );
      }
      commit('setRelatedKeywords', {
        relatedKeywords: res.data.related_keywords,
        req,
        searchUrl,
      });
    }
  },

  /**
   * 最後の検索リクエストに使われたURLを更新する。
   * キーワードランキングの更新もdispatchする。
   * @param  {String} searchUrl  ～/x_search.x
   */
  updateSearchUrl(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    searchUrl: string,
  ): void {
    commit('prepDataMap', { searchUrl });
    commit('setSearchUrl', searchUrl);
    dispatch('requestKeywordRanking', { searchUrl });
  },
  startPagelog(): void {
    // dummy
  },

  /**
   * searchUrl に対応する関連キーワードを有効にする
   * @param  {String} searchUrl ～/x_search.x
   */
  enableRelatedKeywords(
    { state, commit }: { state: StoreState; commit: Commit },
    { searchUrl }: { searchUrl: string },
  ): void {
    searchUrl = searchUrl || state.searchUrl;
    commit('useRelatedKeywords', { searchUrl });
  },

  /**
   * searchUrl に対応するキーワードランキングを有効にして、
   * キーワードランキング更新をdispatchする。
   * @param  {String} searchUrl ～/x_search.x
   */
  enableKeywordRanking(
    {
      state,
      commit,
      dispatch,
    }: { state: StoreState; commit: Commit; dispatch: Dispatch },
    { searchUrl }: { searchUrl: string },
  ): void {
    searchUrl = searchUrl || state.searchUrl;
    commit('prepDataMap', { searchUrl });
    commit('useKeywordRanking', { searchUrl });
    dispatch('requestKeywordRanking', { searchUrl });
  },

  /**
   * searchUrl に対応するキーワードランキングを更新するためのjsonpを発行する。
   * @param  {String} searchUrl ～/x_search.x
   */
  requestKeywordRanking(
    { state, commit }: { state: StoreState; commit: Commit },
    { searchUrl }: { searchUrl: string },
  ): void {
    searchUrl = searchUrl || state.searchUrl;
    if (!searchUrl) return;

    if (!state.dataMap[searchUrl].keywordRanking.use) return;

    commit('prepDataMap', { searchUrl });
  },

  /**
   * リザルトフレームを表示する
   * @param  {Object} doc       ref. https://c.marsflag.com/mf/mfx/1.0/doc/x_search_spec.html#ZubakenDoc https://c.marsflag.com/mf/mfx/1.0/doc/x_search_spec.html#OrganicDoc
   * @param  {DOMEvent} event   トリガーとなったイベント
   * @param  {Boolean} needCheck リザルトフレームがクローズしてるかのチェックの要否
   */
  showResultframe(
    { state }: { state: StoreState },
    {
      doc,
      event,
      needCheck,
    }: { doc: OrganicDoc; event: Event; needCheck: boolean },
  ): void {
    // console.log('actions.showResultframe')
    if (!(G2 && G2.BAR && G2.LAY)) return;

    if (needCheck && G2.LAY.fClosed) return;

    // matchMediaでチェック
    if (!checkResultframeMatchMedia()) return;

    // GeniusUI用
    try {
      G2.BAR.setPhrase(state.query.q);
    } catch (e) {
      console.error(e);
    }
    try {
      G2.LAY.procEnter(event, doc);
    } catch (e) {
      console.error(e);
    }
  },
};

export { actions };
